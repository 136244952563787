.form-login {
	max-width: 330px;
	padding: 15px;
	margin: 0 auto;
	width: 100%;
	border-radius: 5px;
	background-color: white;
}

.btn-push {
	position: absolute;
	bottom: 0px;
	margin: 0;
	padding: 4px;
}

.btn-del {
	margin: 4px
}


.td-del {
	padding: 0 !important;
}

html, body{
	height: 100%;
}


body, .bg-ws, .row {
	background-color: whitesmoke;
}

.bg-isley, .table .thead-dark th {
	background-color: #234b2a!important;
}

.btn-isley{
	background-color: #309D44!important;
	border-color: #309D44!important;
	color: white;
}

.full-height {
	height: 100vh;
}

.main {
	min-height: 100%;
	background-image: url(../css/leaves-pattern.png);
}



@media (max-width:575px) {
	.display-4 {
		font-size: 1.5rem;
	}

	.day h5 {
		background-color: #d8e5db;
		padding: 3px 5px 5px;
		margin: -8px -8px 8px -8px;
	}

	.date {
		padding-left: 4px;
	}
}

@media (min-width: 576px) {
	.day {
		height: auto; /* was 100% */
		position: relative;
		text-align: center;
		padding-top: 9%!important; /* create relationship between width and height */
	}

	.day h5 {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		margin: 0;
	}


}

.full-center {
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

button {
	margin: 10px 0;
}

.container {
	height: 100%;
}

myauto {
	margin-bottom: auto;
	margin-top: auto;
}


.highlight {
	background-color: #309D44;
}

.square {
	float: left;
	position: relative;
	width: 14.2855%;
	padding-bottom: 14.2855%; /* = width for a 1:1 aspect ratio */
	overflow: hidden;
}

.content {
	position: absolute;
	height: 90%; /* = 100% - 2*5% padding */
	width: 90%; /* = 100% - 2*5% padding */
	padding: 5%;
}

.table {
	display: table;
	width: 100%;
	height: 100%;
}

.table-cell {
	display: table-cell;
	vertical-align: middle;
}

.table-fix th{
	overflow-wrap: anywhere;
}


.overflow{
	overflow-wrap: anywhere;
}

.daynum {
	position: absolute;
	top: 0;
}

.appnum {
	position: absolute;
	bottom: -20%;
	font-size: 13px;
}

.namelist > button {
	margin: 0;
	border-radius: 0;
}


.btn-group.special {
	display: flex;
}

.special .btn {
	flex: 1
}

.filled-square, .no-day-square, .table-striped tbody tr:nth-of-type(odd), .input-group-text {
	background-color: #d8e5db!important;
}

.empty-square {
	background-color: ghostwhite;
}

.app-list-label {
	border-color: grey;
	border-bottom-style: solid;
	border-bottom-width: 1px;
}

.app-list-border{
	border-bottom-style: dotted;
	border-bottom-width: 1px;
}

.border {
	border-color: darkgray!important;
}

.btn-group-vertical {
	display: block!important;
}

.height-cap {
	height:800px
}

.force-scroll{
	height: 100%;
	overflow-x: hidden;
}


.btn-top{
	display: none;
	position: fixed;
	bottom: 20px;
	right: 30px;
	z-index: 99;
}
